import { GenericVehicleDetails } from '@finn/ua-vehicle';
import { cn } from '@finn/ui-utils';
import get from 'lodash/get';
import React from 'react';
// TODO design-system: refactor !important styles after migration to new typography APP-1443

interface IProps {
  vehicle: GenericVehicleDetails;
  className?: string;
}
const ProductLabel: React.FunctionComponent<IProps> = ({
  vehicle,
  className,
}) => {
  const label = get(vehicle, 'product_label.label');
  // Out if there is no label
  if (!label) {
    return null;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks

  return (
    <span
      className={cn(
        '!border-pearl inline-flex max-w-[70%] rounded-sm border border-solid !bg-white p-1.5 text-center md:p-2',
        className
      )}
    >
      <p
        className={
          'body-12-medium overflow-hidden text-ellipsis whitespace-nowrap py-1'
        }
      >
        {label}
      </p>
    </span>
  );
};

export default ProductLabel;
