import { useMonthlySubscriptionCost } from '@finn/auto-ui/modules/products/details/components/PriceBreakdown/hooks/useMonthlySubscriptionCost';
import { getForBusinessCookie, usePreDiscountRawValue } from '@finn/ui-modules';
import { cn, getFormattedPrice } from '@finn/ui-utils';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import AvailabilityText from '~/components/ProductDetails/AvailabilityText';
import PDPTracking from '~/components/ProductDetails/utils/PDPTracking';
import { GenericVehicleDetails, getEquipmentLine } from '~/contexts/Vehicle';
import { usePartnerDiscount } from '~/hooks/usePartnerDiscount';

import { useConfigureStore } from '../../ConfigurePage';

export type Props = {
  className?: string;
  position?: 'top' | 'bottom';
  isAvailable: boolean;
  show: boolean;
  infoLines?: string[];
  vehicle: GenericVehicleDetails;
  renderSubmitButton(): React.ReactNode;
};

const StickyBar: React.FC<Props> = ({
  vehicle,
  className,
  isAvailable,
  renderSubmitButton,
  show,
  position = 'top',
}) => {
  // Add a bottom padding to prevent StickyBar from overlapping the footer
  useEffect(() => {
    if (position === 'bottom') {
      document.body.style.paddingBottom = '88px';

      return () => {
        document.body.style.paddingBottom = '';
      };
    }
  }, [position]);

  const stickyTopRef = useRef<HTMLDivElement | null>(null);
  const [loaded, setLoaded] = useState(false);
  const i18n = useIntl();
  const equipmentLine = getEquipmentLine(vehicle);
  const configLineLabel = `${vehicle.engine ?? ''} ${equipmentLine}`;
  const isForBusiness = getForBusinessCookie();
  const partnerDiscount = usePartnerDiscount();

  const { term, downPayment: isDownPayment } = useConfigureStore();

  let { total } = useMonthlySubscriptionCost(vehicle);
  const preDiscountPrice = usePreDiscountRawValue(
    vehicle,
    total,
    term,
    isForBusiness,
    isDownPayment
  );
  let originalPrice = preDiscountPrice ? preDiscountPrice : null;

  if (partnerDiscount > 0) {
    if (!originalPrice) {
      originalPrice = total;
    }
    total = total - total * partnerDiscount;
  }

  const finalPriceFormatted = getFormattedPrice(i18n, total, 0);
  const originalPriceFormatted = getFormattedPrice(i18n, originalPrice, 0);

  const subtitle = i18n.formatMessage({
    id: `pdp.price.${isForBusiness ? 'b2b' : 'b2c'}_vat`,
  });

  const rootClassName = cn(
    'fixed z-10 top-0 left-0 right-0 h-[88px] shadow-highlightPewter transition-transform -translate-y-full bg-white',
    {
      'top-auto left-0 bottom-0 right-0 translate-y-full':
        position === 'bottom',
      'translate-y-0': show,
    },
    className
  );

  // Delay always a second to prevent issue between desktop/mobile position
  useEffect(() => {
    setTimeout(() => setLoaded(true), 1000);
  }, []);

  if (!loaded) return;

  return (
    <div className={rootClassName} ref={stickyTopRef} data-appid="sticky-bar">
      <div className="container">
        <div className="flex h-[88px] max-w-full items-center">
          <div className="hidden flex-shrink-0 flex-grow basis-[100px] overflow-hidden whitespace-nowrap pr-2 md:block">
            <div className="mb-2">
              <span className="global-t5-semibold inline-block">
                {vehicle.brand.id} {vehicle.model}
              </span>
              <span className="global-t5-light mt-2 inline-block">
                &nbsp;{configLineLabel}
              </span>
            </div>
            <AvailabilityText showSmallerVersion />
          </div>
          <div className="flex-1 basis-[132px] sm:flex-shrink-0 sm:flex-grow sm:basis-auto md:mr-4 md:flex-grow-0">
            <div className="flex items-center justify-start">
              <span className="global-t5-semibold inline-block">
                {i18n.formatMessage({ id: 'pdp.price.starting_from' })}&nbsp;
              </span>
              {originalPrice && (
                <span className="global-t5-semibold text-iron mr-2 inline-block line-through">
                  {originalPriceFormatted}
                </span>
              )}
              <span className="global-t5-semibold inline-block">
                {finalPriceFormatted}
              </span>
            </div>
            <p className="body-14-regular mt-1.5 flex items-center justify-start md:justify-end">
              {subtitle}
            </p>
          </div>
          <div
            onClick={PDPTracking.stickyAddToCartClicked}
            className="w-1/2 sm:w-auto "
          >
            {isAvailable && renderSubmitButton()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StickyBar;
