import { cn } from '@finn/ui-utils';
import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';

import VehicleContext, { getEarliestDelivery } from '~/contexts/Vehicle';
import { isVehicleComingSoon } from '~/utils/pdp';

const AvailabilityText: React.FC<{
  showSmallerVersion?: boolean;
  className?: string;
}> = ({ showSmallerVersion, className = '' }) => {
  const intl = useIntl();
  const { vehicle } = useContext(VehicleContext);
  const isComingSoon = isVehicleComingSoon(vehicle);
  const availabilityText = useMemo(() => {
    const date = getEarliestDelivery({ vehicle, intl });

    return intl.formatMessage({ id: 'plp.availableFrom' }, { date });
  }, [intl, vehicle]);

  return (
    <h6
      className={cn(`global-t6-semibold ${className}`, {
        'text-iron mb-2': isComingSoon,
        'text-green mb-2': !isComingSoon,
        'body-12-semibold': !!showSmallerVersion,
      })}
    >
      {availabilityText}
    </h6>
  );
};
export default AvailabilityText;
