import { cn } from '@finn/ui-utils';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import useKeyDataItems from '~/components/KeyData/hooks/useKeyDataItems';
import VehicleContext from '~/contexts/Vehicle';

import { useIsPDPCarInfoExp } from '../ProductDetails/utils/useIsPDPCarInfoExp';
import KeyDataList from './KeyDataList';

const KeyDataBox: React.FunctionComponent = () => {
  const drawerView = useIsPDPCarInfoExp();

  const i18n = useIntl();
  const { vehicle } = useContext(VehicleContext);
  const items = useKeyDataItems(vehicle);
  const title = i18n.formatMessage({ id: 'pdp.keyDataTitle' });

  return (
    <div
      className={cn('scroll-mt-[8em]', {
        'mb-8': drawerView,
        'mt-16 sm:mt-40': !drawerView,
      })}
      id="product_info"
    >
      <div className="container">
        <h2
          className={
            drawerView
              ? 'body-16-semibold'
              : 'mobile-t3-semibold md:web-t3-semibold mb-10'
          }
        >
          {title}
        </h2>
        <KeyDataList items={items} />
      </div>
    </div>
  );
};
export default KeyDataBox;
