type PriceDisplayProps = {
  subtitle: string;
  finalPrice: string;
  originalPrice: string;
  isShortPrice: boolean;
  hasOriginalPrice: boolean;
};

export const PriceDisplay = ({
  originalPrice,
  finalPrice,
  subtitle,
  isShortPrice,
  hasOriginalPrice,
}: PriceDisplayProps) => {
  const priceClass = isShortPrice
    ? 'sm:mobile-t4-semibold md:web-t4-semibold'
    : 'sm:mobile-t3-semibold md:web-t3-semibold';

  return (
    <div className="flex flex-col justify-center gap-2 py-2 sm:gap-4 sm:py-0">
      <div className="flex gap-2">
        {hasOriginalPrice && (
          <span
            className={`global-t5-semibold ${priceClass} text-iron line-through`}
          >
            {originalPrice}
          </span>
        )}
        <span className={`global-t5-semibold ${priceClass} !text-black`}>
          {finalPrice}
        </span>
      </div>
      <span className="body-14-light">{subtitle}</span>
    </div>
  );
};
