import React, { useContext } from 'react';

import ProductLabel from '~/components/ProductLabel';
import VehicleContext from '~/contexts/Vehicle';
import { isVehicleComingSoon } from '~/utils/pdp';

const ProductLabelsBar: React.FunctionComponent = () => {
  const { vehicle } = useContext(VehicleContext);
  const isComingSoon = isVehicleComingSoon(vehicle);

  return (
    <div className="container flex w-full items-center justify-between">
      {!isComingSoon && <ProductLabel vehicle={vehicle} />}
    </div>
  );
};

export default ProductLabelsBar;
