import { ArrowDirection, CarouselArrow } from '@finn/ui-modules';
import { cn } from '@finn/ui-utils';
import React, { MouseEventHandler } from 'react';

import PDPTracking from '~/components/ProductDetails/utils/PDPTracking';

type Props = {
  direction: ArrowDirection;
  onClick?: MouseEventHandler | undefined;
};

const ImageGalleryArrow: React.FC<Props> = ({ direction, onClick }) => {
  const handleClick = (e: React.MouseEvent) => {
    onClick(e);
    if (direction === ArrowDirection.left) {
      PDPTracking.previousArrowClicked();
    } else {
      PDPTracking.nextArrowClicked();
    }
  };

  return (
    <CarouselArrow
      direction={direction}
      onClick={(e) => handleClick(e)}
      className={cn(
        'active:!bg-cotton absolute bottom-6 z-[2] md:static',
        direction === ArrowDirection.left
          ? 'left-4 mr-2 md:order-1'
          : 'right-4 ml-2 md:order-3'
      )}
      arrowSize={20}
    />
  );
};
export default ImageGalleryArrow;
