import { Button } from '@finn/design-system/atoms/button';
import { Image } from '@finn/ui-components';
import { cn } from '@finn/ui-utils';
import React, { useCallback } from 'react';

import PDPTracking from '~/components/ProductDetails/utils/PDPTracking';
import { usePartnerDiscount } from '~/hooks/usePartnerDiscount';

import { REFERENCE_IMAGE_HEIGHT, REFERENCE_IMAGE_WIDTH } from './constants';

type Props = {
  imageUrls: string[];
  imageSrcSets: string[];
  labelsBar: React.ReactNode;
  showDisclaimer?: boolean;
  disclaimerText: string;
  fullScreen: boolean;
  setFullScreen: Function;
  setInitialSlide: Function;
  altText?: string;
};

const ImageGalleryDesktop: React.FC<Props> = ({
  imageUrls,
  imageSrcSets,
  labelsBar,
  showDisclaimer,
  disclaimerText,
  fullScreen,
  setFullScreen,
  setInitialSlide,
  altText = 'car',
}) => {
  const partnerDiscount = usePartnerDiscount();

  const onImageClicked = useCallback(
    (index: number) => () => {
      if (!fullScreen) {
        setInitialSlide(index);
        setFullScreen(true);
        if (index === 0) {
          PDPTracking.primaryImageClicked();
        } else {
          PDPTracking.secondaryImagesClicked();
        }
      }
    },
    [fullScreen, setFullScreen, setInitialSlide]
  );

  const getImageClassName = (index: number) =>
    index === 0
      ? 'col-start-1 col-end-3 relative focus-visible:shadow-md bg-snow'
      : 'w-full focus-visible:shadow-md bg-snow';

  const images = imageUrls.slice(0, 5).map((imageUrl, index) => (
    <Button
      key={index}
      variant="ghost"
      className={cn(getImageClassName(index), 'h-max w-full p-0')}
      onClick={onImageClicked(index)}
    >
      {index === 0 && (
        <>
          <div className="absolute left-4 right-0 top-0 sm:top-4">
            {!partnerDiscount && labelsBar}
          </div>
          <div className="body-12-medium border-pearl absolute bottom-4 right-4 rounded-sm border border-solid bg-white px-2 py-3">
            1 / {imageUrls?.length}
          </div>
          {showDisclaimer && (
            <div
              className={cn(
                'body-12-regular absolute bottom-0 mb-1 w-full py-4 pl-4 pr-20 text-right'
              )}
            >
              {disclaimerText}
            </div>
          )}
        </>
      )}
      <Image
        className="h-auto min-h-20 w-full cursor-pointer mix-blend-multiply "
        src={imageUrl}
        srcSet={imageSrcSets[index]}
        width={REFERENCE_IMAGE_WIDTH}
        height={REFERENCE_IMAGE_HEIGHT}
        alt={altText}
      />
    </Button>
  ));

  return (
    <div className="sticky top-2">
      <div className="grid grid-cols-2 gap-5">{images}</div>
    </div>
  );
};
export default ImageGalleryDesktop;
