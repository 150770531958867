import { Button } from '@finn/design-system/atoms/button';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import { PLPRoute } from '~/types/general';

// TODO design-system: refactor !important styles after migration to new typography APP-1443
import { AddToCartContext } from './AddToCartProvider';

const ComingSoonSection = () => {
  const i18n = useIntl();
  const router = useRouter();
  const { onAddToCart } = useContext(AddToCartContext);

  return (
    <>
      <div className="border-pearl bg-pearl my-6 w-full border-t sm:my-10" />
      <h5 className="global-t5-semibold my-2">
        {i18n.formatMessage({ id: 'pdp.comingSoon.beTheFirst' })}
      </h5>
      <p className="body-16-light my-4">
        {i18n.formatMessage({
          id: 'pdp.comingSoon.thisCarIsComingSoon',
        })}
      </p>
      <div className="mb-4">
        <Button data-cy="reserve-coming-soon" onClick={onAddToCart}>
          {i18n.formatMessage({
            id: 'pdp.comingSoon.openPopupLabel',
          })}
        </Button>
      </div>
      <div>
        <Button
          data-cy="browse-more"
          variant="outline"
          onClick={() => {
            router.push(PLPRoute.SUBSCRIPTION);
          }}
        >
          {i18n.formatMessage({
            id: 'pdp.comingSoon.browseMoreLabel',
          })}
        </Button>
      </div>
    </>
  );
};
export default ComingSoonSection;
