import { Features, useIsABVariant } from '@finn/ua-featureflags';
import React, { useContext } from 'react';

import VehicleContext from '~/contexts/Vehicle';

import { AvailabilityBox } from '../AvailabilityBox';
import LeftInStock from '../LeftInStock';

interface IProps {
  renderSubmitButton(): React.ReactNode;
}

const AvailabilityElements: React.FunctionComponent<IProps> = ({
  renderSubmitButton,
}) => {
  const { vehicle } = useContext(VehicleContext);
  const isExpPDPAvailabilityLabelExp = useIsABVariant(
    Features.ExpPDPAvailabilityLabel
  );

  return (
    <>
      {renderSubmitButton()}

      <div className="mb-1 mt-4 space-y-2">
        <LeftInStock
          sm={isExpPDPAvailabilityLabelExp}
          productStockTotal={vehicle.product_stock_total}
        />
        {isExpPDPAvailabilityLabelExp && <AvailabilityBox />}
      </div>
    </>
  );
};

export default AvailabilityElements;
