import { ContinueButtonLocation } from '@finn/auto-ui/types/pdp';
import {
  TrustPilotRating,
  TrustpilotStyle,
} from '@finn/auto-ui/ui-elements/TrustPilotRating';
import { Button } from '@finn/design-system/atoms/button';
import { useMediaQuery } from '@finn/design-system/helpers/media';
import { getAppSDK } from '@finn/ua-app';
import { EmissionModalContext } from '@finn/ua-emission';
import { Features, useGetFeature, useIsABVariant } from '@finn/ua-featureflags';
import {
  ModalKey,
  useIsAnyModalOpen,
  useIsModalOpen,
  useOpenModal,
} from '@finn/ua-modals';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { cn, useCurrentLocale } from '@finn/ui-utils';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  useConfigureStore,
  useInitializeConfigureStore,
} from '~/components/ConfigurePage';
import AvailabilityText from '~/components/ProductDetails/AvailabilityText';
import ComingSoonSection from '~/components/ProductDetails/ComingSoonSection';
import ImageGallery from '~/components/ProductDetails/ImageGallery';
import StickyBar from '~/components/ProductDetails/StickyBar/StickyBar';
import PromoBanner from '~/components/PromoBanner';
import VehicleContext from '~/contexts/Vehicle';
import useIsScrolledUp from '~/hooks/useIsScrolledUp';
import { PDPData } from '~/types/cosmicModules';
import { getUrlFromFilter } from '~/utils/filter';
import { isVehicleComingSoon } from '~/utils/pdp';

import { AddToCartContext } from './AddToCartProvider';
import { ClickableVehiclesBenefits } from './ClickableVehicleBenefits';
import { ContinueButton } from './ContinueButton';
import { isPreloved } from './helpers';
import { PreLovedInfo } from './PreLoved';
import PriceArea from './PriceArea';
import ProductBreadcrumbs from './ProductBreadcrumbs';
import ProductLabelsBar from './ProductLabelsBar';
import VehiclesColor from './VehiclesColor';
import VehiclesModel from './VehiclesModel';

interface IProps {
  pageData: PDPData;
  hideBreadCrumbs?: boolean;
}

const ProductDetails: React.FunctionComponent<IProps> = ({
  pageData,
  hideBreadCrumbs,
}) => {
  const { isUSA } = useCurrentLocale();
  const i18n = useIntl();
  const isMobile = useMediaQuery('sm');
  const [targetRef, isScrolledUp] = useIsScrolledUp<HTMLDivElement>();
  const [galleryFullScreen, setGalleryFullScreen] = useState(false);
  const { vehicle } = useContext(VehicleContext);
  const { modalOpen } = useContext(EmissionModalContext);
  const isComingSoon = isVehicleComingSoon(vehicle);
  const { onAddToCart, isLoading } = useContext(AddToCartContext);
  const { onContinue, isLoading: isLoadingFromPDPConfigModal } =
    useConfigureStore();
  const isPDPConfigModalOpen = useIsModalOpen(ModalKey.CONFIGURE_VEHICLE);
  const isAvailable = !!vehicle.availability;
  const pictures = vehicle.pictures.map((pic) => pic?.url).filter(Boolean);
  const disclaimerText = pageData.metadata.content.bmw_rims_disclaimer;
  const isAnyModalOpen = useIsAnyModalOpen();

  const isExpPDPAvailabilityLabel = useIsABVariant(
    Features.ExpPDPAvailabilityLabel
  );
  const shouldShowStickyTopBar =
    (galleryFullScreen || isScrolledUp) && !modalOpen && !isAnyModalOpen;

  const breadcrumbs = useMemo(() => {
    const brandUrl = getUrlFromFilter({
      brands: [vehicle.brand.id.toLowerCase()],
    });
    const homeTitle = i18n.formatMessage({ id: 'general.home' });

    return [
      {
        title: homeTitle,
        url: '/',
      },
      {
        title: vehicle.brand.id,
        url: brandUrl,
      },
      {
        title: vehicle.model,
        url: getUrlFromFilter({
          brands: [vehicle.brand.id.toLowerCase()],
          models: [vehicle.model.toLowerCase()],
        }),
      },
    ];
  }, [i18n, vehicle.brand.id, vehicle.model]);

  const handleGalleryFullScreenChange = useCallback((value) => {
    setGalleryFullScreen(value);

    getAppSDK()?.sendMessageToApp?.({
      type: `event:${value ? 'open' : 'close'}_modal`,
      value: 'fullscreen_gallery',
    });
  }, []);

  const isSM = useMediaQuery('sm');

  const shouldShowDisclaimer = useMemo(() => {
    const { brand, purchasing_deal_name } = vehicle;

    return brand.id === 'BMW' && purchasing_deal_name?.includes('L');
  }, [vehicle]);

  // Allow to render promo in case of there is no patterns provided OR
  // In case any of the patterns is substring of current vehicle id
  const shouldRenderPromoSection = useMemo(() => {
    const supportedIdsPatterns =
      pageData.metadata.content.supported_ids_patterns.patterns;

    return (
      !supportedIdsPatterns.length ||
      supportedIdsPatterns.some((pattern: string) =>
        vehicle.id.includes(pattern)
      )
    );
  }, [pageData, vehicle.id]);

  useInitializeConfigureStore(vehicle);

  const renderContinueButton = useCallback(
    (location: ContinueButtonLocation) => (
      <ContinueButton
        onClick={onAddToCart}
        isLoading={isLoading}
        location={location}
        buttonClassName={cn({ 'p-4': isSM })}
      />
    ),
    [isLoading, onAddToCart]
  );

  const renderContinueToCheckoutButton = useMemo(
    () => (
      <ContinueButton
        onClick={onContinue}
        isLoading={isLoadingFromPDPConfigModal}
        location={ContinueButtonLocation.STICKY}
      />
    ),
    [isLoadingFromPDPConfigModal, onContinue]
  );

  const isPreLoved = isPreloved(vehicle);

  const stickySubmitButton = useCallback(
    () =>
      isPDPConfigModalOpen
        ? renderContinueToCheckoutButton
        : renderContinueButton(ContinueButtonLocation.STICKY),
    [isPDPConfigModalOpen, renderContinueButton, renderContinueToCheckoutButton]
  );

  const mainSubmitButton = useCallback(
    () => renderContinueButton(ContinueButtonLocation.MAIN),
    [renderContinueButton]
  );
  const openModal = useOpenModal();

  const carInfoExp = useGetFeature(Features.ExpPDPCarInfo);
  const isCarInfoB = carInfoExp === 'b';
  const isCarInfoC = carInfoExp === 'c';

  const handleMoreInformationClick = () => {
    interactionTrackingEvent(TrackingEventName.MODAL_OPENED, {
      name: 'carinfo',
    });
    openModal(ModalKey.PDP_CAR_INFO);
  };

  return (
    <>
      {!isComingSoon && (
        <StickyBar
          vehicle={vehicle}
          show={shouldShowStickyTopBar}
          isAvailable={isAvailable}
          renderSubmitButton={stickySubmitButton}
          position={isMobile ? 'bottom' : 'top'}
        />
      )}
      <div className="container">
        {hideBreadCrumbs ? null : (
          <div className="flex items-center justify-between">
            <ProductBreadcrumbs breadcrumbs={breadcrumbs} />
          </div>
        )}
        <section className="m-auto flex flex-wrap">
          <div
            className={cn(
              'max-w-full grow-0 basis-full md:pr-10',
              `md:max-w-[calc(100%*2/3)] md:basis-[calc(100%*2/3)]`
            )}
          >
            {shouldRenderPromoSection && (
              <PromoBanner
                pageData={pageData}
                isPdp
                className="mb-4 mt-0 sm:mb-4 sm:min-h-[10px]"
              />
            )}
            <ImageGallery
              imageUrls={pictures}
              labelsBar={<ProductLabelsBar />}
              showDisclaimer={shouldShowDisclaimer}
              disclaimerText={disclaimerText}
              fullScreen={galleryFullScreen}
              setFullScreen={handleGalleryFullScreenChange}
            />
          </div>
          <div
            className={cn(
              'relative m-auto max-w-full grow pt-6 sm:max-w-full sm:basis-full md:m-0 md:pt-0',
              `md:max-w-[calc(100%/3)] md:basis-[calc(100%/3)]`
            )}
          >
            <div className="sticky top-2">
              {!isExpPDPAvailabilityLabel && <AvailabilityText />}
              <VehiclesModel />
              {isCarInfoB && (
                <Button variant="action" onClick={handleMoreInformationClick}>
                  {i18n.formatMessage({ id: 'pdp.carInfo' })}
                </Button>
              )}
              {isCarInfoC && (
                <Button
                  onClick={handleMoreInformationClick}
                  size="md"
                  variant="outline"
                  className="mt-2"
                >
                  {i18n.formatMessage({ id: 'pdp.carInfo' })}
                </Button>
              )}
              <div className="border-pearl my-4 h-0 w-full border-t" />
              <VehiclesColor />
              <div className="border-pearl my-4 h-0 w-full border-t" />

              {isPreLoved && <PreLovedInfo pdpData={pageData} />}
              <PriceArea
                isAvailable={isAvailable}
                renderSubmitButton={mainSubmitButton}
              />
              <div ref={targetRef} />

              {!isAvailable && !isUSA ? (
                <ComingSoonSection />
              ) : (
                <>
                  <div className="border-pearl my-4 h-0 w-full border-t" />

                  <TrustPilotRating
                    style={TrustpilotStyle.PDP}
                    desktopClassName="mb-4 sm:-left-[40px]"
                    mobileClassName="mb-4 sm:-left-[40px]"
                    width="250px"
                    mobileHeight="20px"
                    desktopHeight="20px"
                  />

                  <ClickableVehiclesBenefits />
                </>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ProductDetails;
