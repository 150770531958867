import { ConditionalWrapper } from '@finn/auto-ui/utils/general';
import { SpacingWrapper } from '@finn/platform-modules';
import { cn } from '@finn/ui-utils';
import React from 'react';

import Equipment from '~/components/Equipment';
import EquipmentDetails from '~/components/EquipmentDetails';

import { useIsPDPCarInfoExp } from '../ProductDetails/utils/useIsPDPCarInfoExp';

export const EquipmentDetailsComponent: React.FC<{
  productDetailsData: any;
}> = ({ productDetailsData }) => {
  const drawerView = useIsPDPCarInfoExp();

  return (
    <div className={cn(drawerView && 'pt-8', 'container')}>
      <ConditionalWrapper
        condition={!drawerView}
        wrapper={(wrapperChildren: React.FC) => (
          <SpacingWrapper>{wrapperChildren}</SpacingWrapper>
        )}
      >
        <div
          className={cn('w-full', !drawerView ? 'grid-cols-2' : 'grid-cols-1')}
        >
          <Equipment productDetailsData={productDetailsData} />
          <EquipmentDetails />
        </div>
      </ConditionalWrapper>
    </div>
  );
};
