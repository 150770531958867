import React, { useState } from 'react';

import RawHtml from '~/components/RawHtml';
import { PDPData } from '~/types/cosmicModules';

import PDPTracking from '../utils/PDPTracking';
import { PreLovedModal } from './PreLovedModal';

interface IProps {
  pdpData: PDPData;
}

export const PreLovedInfo: React.FunctionComponent<IProps> = ({ pdpData }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleInfoClick = (event) => {
    PDPTracking.helpOptionClicked('preloved');
    setModalOpen(true);
    event?.stopPropagation?.();
  };

  const handleModalClose = (event) => {
    setModalOpen(false);
    event.stopPropagation();
  };

  const { description, link, modal_text, modal_title } =
    pdpData?.metadata.content.preloved || {};

  return (
    <div className="bg-snow my-4 py-5 pl-4 pr-5">
      <span className="body-14-regular inline">
        <RawHtml html={description} />
        <a
          href="javascript:void(0)"
          className="link-14"
          onClick={handleInfoClick}
        >
          {link}
        </a>
      </span>
      <PreLovedModal
        open={modalOpen}
        onClose={(event) => handleModalClose(event)}
        data={{
          modal_title,
          modal_text,
        }}
      />
    </div>
  );
};
