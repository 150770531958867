import { Button } from '@finn/design-system/atoms/button';
import { useMediaQuery } from '@finn/design-system/helpers/media';
import { Close } from '@finn/design-system/icons/close';
import { Image } from '@finn/ui-components';
import { ArrowDirection } from '@finn/ui-modules';
import { cn, CookieKeys } from '@finn/ui-utils';
import Cookies from 'js-cookie';
import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';

import PinchZoomSlide from '~/components/ProductDetails/ImageGallery/PinchZoomSlide';
import PDPTracking from '~/components/ProductDetails/utils/PDPTracking';
import { usePartnerDiscount } from '~/hooks/usePartnerDiscount';

import ImageGalleryArrow from './ImageGalleryArrow';

type Props = {
  imageUrls: string[];
  imageThumbUrls: string[];
  imageSrcSets: string[];
  labelsBar: React.ReactNode;
  showDisclaimer?: boolean;
  disclaimerText: string;
  fullScreen: boolean;
  setFullScreen: Function;
  initialSlide: number;
  isMobile: Boolean;
  altText?: string;
  pdpDrawer?: boolean;
};

const ImageGalleryMobile: React.FC<Props> = ({
  imageUrls,
  imageThumbUrls,
  imageSrcSets,
  labelsBar,
  showDisclaimer,
  disclaimerText,
  fullScreen,
  setFullScreen,
  initialSlide,
  isMobile,
  pdpDrawer = false,
  altText = 'car',
}) => {
  const partnerDiscount = usePartnerDiscount();
  const isRibbonDismissed = !!Cookies.get(CookieKeys.RIBBON_DISMISSED);
  const [sliderCurrentIndex, setSliderCurrentIndex] = useState(initialSlide);
  const [loaded, setLoaded] = useState(false);
  const totalImageCount = imageUrls?.length;
  const sliderRef = useRef<Slider>(null);

  useEffect(() => {
    sliderRef.current?.slickGoTo(initialSlide, true);
  }, [initialSlide]);
  useEffect(() => {
    setTimeout(() => setLoaded(fullScreen), 100);
  }, [fullScreen]);

  const onKeyboardNav = (e) => {
    if (e?.key === 'ArrowRight') {
      sliderRef?.current?.slickNext?.();
      PDPTracking.nextArrowClicked();
    } else if (e?.key === 'ArrowLeft') {
      sliderRef?.current?.slickPrev?.();
      PDPTracking.previousArrowClicked();
    } else if (e?.key === 'Escape') {
      setFullScreen(false);
      PDPTracking.galleryCloseClicked();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', onKeyboardNav);

    return () => window.removeEventListener('keydown', onKeyboardNav);
  }, []);

  const sliderSettings = {
    dots: !isMobile,
    arrows: fullScreen,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide,
    afterChange: (index: number) => {
      setSliderCurrentIndex(index);
    },
    prevArrow: <ImageGalleryArrow direction={ArrowDirection.left} />,
    nextArrow: <ImageGalleryArrow direction={ArrowDirection.right} />,
    onSwipe: (direction: string) => {
      if (direction === 'right') {
        PDPTracking.previousSwipe();
      } else {
        PDPTracking.nextSwipe();
      }
    },
    customPaging: (i: number) => (
      <a onClick={PDPTracking.thumbnailsClicked}>
        <Image src={imageThumbUrls[i]} alt="thumb" className="max-w-full" />
      </a>
    ),
    dotsClass: cn(
      '[&_.slick-active_img]:max-w-full',
      '[&_li]:max-w-[88px] [&_li]:px-2 [&_li]:inline-block [&_li]:cursor-pointer [&_li_img]:max-w-full order-2 bottom-0 m-0 h-24 -ml-[40px]',
      'list-none flex items-center justify-center [&_a]:inline-block  bg-white [&_li_img]:border [&_li_img]:border-pearl [&_li_img]:border-solid [&_.slick-active_img]:border-black [&_.slick-active_img]:border-2'
    ),
  };

  const rootClassName = cn('flex bg-white h-auto relative', {
    'w-[calc(100%+32px)] -ml-4 sm:w-[calc(100%+160px)] -sm:ml-20': pdpDrawer,
    'w-full ml-0': !pdpDrawer,
    'h-screen fixed top-0 left-0 opacity-0 translate-y-[30%] duration-300 transition-all z-[2]':
      fullScreen,
    'opacity-100 transform-none': loaded,
  });
  const isMD = useMediaQuery('md');

  return (
    <div
      className={rootClassName}
      data-appid={`image-gallery-mobile-${fullScreen ? 'fullScreen' : ''}`}
    >
      {fullScreen && (
        <Button
          variant="ghost"
          data-appid="image-gallery-mobile-close-button"
          className={cn(
            'absolute right-2 top-[104px] z-[2] h-max scale-75 rounded-full p-2',
            {
              'top-[104px]': isMD && isRibbonDismissed,
              'top-[168px]': isMD && !isRibbonDismissed,
            }
          )}
          onClick={() => {
            setFullScreen(false);
            PDPTracking.galleryCloseClicked();
          }}
        >
          <Close />
        </Button>
      )}
      <div
        className={cn(
          '[&_.slick-list]:w-full [&_.slick-track]:flex [&_.slick-track]:items-center',
          'mt-0 w-full self-center sm:mb-0 [&_.slick-slider]:flex [&_.slick-slider]:flex-wrap [&_.slick-slider]:items-center [&_.slick-slider]:justify-center',
          {
            'bg-snow mb-0 sm:mt-0': !fullScreen,
            'mb-[88px] bg-white sm:mt-[88px]': fullScreen,
          }
        )}
        onClick={() => {
          if (!fullScreen) {
            setFullScreen(true);
            PDPTracking.primaryImageClicked();
          }
        }}
      >
        <Slider ref={sliderRef} {...sliderSettings}>
          {imageUrls.map((picture, i) => (
            <Button
              key={picture}
              variant="ghost"
              className={cn(
                'border-snow relative h-full w-full border-l border-r p-0 before:hidden after:hidden focus-visible:ring-0',
                {
                  'bg-snow': !fullScreen,
                }
              )}
            >
              <PinchZoomSlide
                url={picture}
                srcSet={imageSrcSets[i]}
                className={cn(
                  'min-h-[198px] w-full min-w-72 items-center object-contain mix-blend-multiply',
                  {
                    'h-[calc(100vh-88px)] max-h-screen': fullScreen,
                    'h-auto max-h-[400px]': !fullScreen,
                    'sm:max-h-[270px]': !fullScreen && !pdpDrawer,
                    'sm:max-h-80': !fullScreen && pdpDrawer,
                  }
                )}
                fullScreen={fullScreen}
                altText={altText}
                isFirst={i === 0}
              />
            </Button>
          ))}
        </Slider>
      </div>
      <div
        className={cn('absolute left-2 right-0 top-2 sm:top-6', {
          hidden: fullScreen,
        })}
      >
        {!partnerDiscount && labelsBar}
      </div>
      {isMobile && (
        <>
          <div
            className={cn(
              'body-12-medium absolute rounded-sm bg-white px-2 py-2',
              {
                'border-pearl bottom-2 right-2 border border-solid':
                  !fullScreen,
                'bottom-[114px] right-1/2 translate-x-1/2 sm:bottom-[68px]':
                  fullScreen,
              }
            )}
          >
            {sliderCurrentIndex + 1} / {totalImageCount}
          </div>
          {showDisclaimer && !fullScreen && (
            <div className="body-12-regular absolute bottom-0 right-16 mb-1 max-w-[80%] text-right">
              {disclaimerText}
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default ImageGalleryMobile;
