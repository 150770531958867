import { ToggleItem } from '@finn/design-system/atoms/toggle';
import { ToggleModule } from '@finn/design-system/modules/ToggleModule';
import { FALLBACK_ADDITIONAL_MILEAGE_FEE } from '@finn/ua-constants';
import { getForBusinessCookie } from '@finn/ui-modules';
import {
  getFormattedNumber,
  getFormattedPrice,
  twoDecimalPlaces,
} from '@finn/ui-utils';
import get from 'lodash/get';
import React, { useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';

import PDPTracking from '~/components/ProductDetails/utils/PDPTracking';
import { useTrackingData } from '~/components/ProductDetails/utils/useTrackingData';
import VehicleContext from '~/contexts/Vehicle';
import useFormattedNumber from '~/hooks/useFormattedNumber';
import { getDistancePrices } from '~/utils/pdp';

import { useConfigureStore } from '../ConfigurePageStore';

type Price = { distance: number; price: number; duration: number };

const DistanceOptions: React.FunctionComponent<
  React.PropsWithChildren<{}>
> = () => {
  const i18n = useIntl();
  const isForBusiness = getForBusinessCookie();
  const { vehicle } = useContext(VehicleContext);
  const { setKilometerPackage, kilometerPackage } = useConfigureStore();

  const vehiclePrice = get(vehicle, 'downpayment_prices', {}) as Record<
    string,
    number
  >;

  const distancePrices: Price[] = getDistancePrices(
    vehiclePrice,
    isForBusiness
  );

  const extraUnitPrice = useFormattedNumber(
    twoDecimalPlaces(
      get(vehicle, 'price.extra_km_price', FALLBACK_ADDITIONAL_MILEAGE_FEE)
    )
  );

  const distanceUnit = i18n.formatMessage({ id: 'general.distanceUnit' });

  const title = i18n.formatMessage({
    id: 'pdp.mileagePerMonth',
  });

  const disclaimer = i18n.formatMessage(
    {
      id: `pdp.mileagePackageDisclaimer.b2c`,
    },
    { extraUnitPrice }
  );

  const disclaimerTooltip = i18n.formatMessage({
    id: 'pdp.mileagePackageDisclaimerTooltip',
  });

  const trackingData = useTrackingData();
  const trackDistanceClicked = useCallback(
    (distance: string) => {
      PDPTracking.distanceClicked({
        ...trackingData,
        distancepackage: distance,
      });
    },
    [trackingData]
  );

  return (
    <div className="mt-5 md:mt-4">
      <ToggleModule
        className="mt-1"
        cols={2}
        title={title}
        titleAs={'h6'}
        defaultValue={String(kilometerPackage)}
        value={String(kilometerPackage)}
        onValueChange={(value) => {
          trackDistanceClicked(title);
          if (value) {
            setKilometerPackage(Number(value));
          }
        }}
        subtitle={`${disclaimer} ${disclaimerTooltip}`}
      >
        {distancePrices.map((option) => {
          const description =
            option.price > 0
              ? `+ ${getFormattedPrice(i18n, option.price, 0)}`
              : i18n.formatMessage({ id: 'pdp.free' });

          return (
            <ToggleItem
              key={option.distance}
              variant="regular"
              title={`${getFormattedNumber(
                i18n,
                option.distance
              )} ${distanceUnit}`}
              value={String(option.distance)}
              description={description}
            />
          );
        })}
      </ToggleModule>
    </div>
  );
};

export default React.memo(DistanceOptions);
