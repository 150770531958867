import { Collapse } from '@finn/platform-modules';
import React, { useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';

import PDPTracking from '~/components/ProductDetails/utils/PDPTracking';
import { useTrackingData } from '~/components/ProductDetails/utils/useTrackingData';
import VehicleContext from '~/contexts/Vehicle';
import { parseEquipmentData } from '~/utils/pdp';

import { useIsPDPCarInfoExp } from '../ProductDetails/utils/useIsPDPCarInfoExp';
import EquipmentCollapseContent from './EquipmentCollapseContent';

type EquipmentItem = {
  key: string;
  items: {
    id: number;
    title: string;
    key: string;
  }[];
};

export const Title = ({ title }: { title: string }) => (
  <p className="body-16-semibold">{title}</p>
);

const EquipmentDetails: React.FunctionComponent = () => {
  const { vehicle } = useContext(VehicleContext);
  const i18n = useIntl();
  const isExpPDPCarInfo = useIsPDPCarInfoExp();

  const equipmentItems = parseEquipmentData(
    vehicle.equipment || {},
    vehicle.equipment_delimiter || ', '
  );

  const packageItems = parseEquipmentData(
    vehicle.equipment_packages || {},
    vehicle.equipment_delimiter || ', '
  );

  const trackingData = useTrackingData();

  const trackEquipmentCollapseClicked = useCallback(
    (title: string, expanded: boolean) => {
      if (expanded) {
        PDPTracking.equipmentExpandClick({ value: title, ...trackingData });
      } else {
        PDPTracking.equipmentCollapseClick({ value: title, ...trackingData });
      }
    },
    [trackingData]
  );

  const renderItem = useCallback(
    (item: EquipmentItem, index: number) => {
      const title = i18n.formatMessage({
        id: `pdp.equipmentDetails.${item.key}`,
      });

      if (isExpPDPCarInfo) {
        return (
          <>
            <p className="body-14-semibold mb-4 mt-8">{title}</p>
            <EquipmentCollapseContent items={item.items} />
          </>
        );
      } else {
        return (
          <Collapse
            key={item.key}
            index={index}
            onValueChange={(expanded?: string[]) =>
              trackEquipmentCollapseClicked(title, Boolean(expanded?.length))
            }
            title={<Title title={title} />}
          >
            <EquipmentCollapseContent items={item.items} />
          </Collapse>
        );
      }
    },
    [i18n, trackEquipmentCollapseClicked]
  );

  const buildEquipmentPackagesContainer = (packages: EquipmentItem[]) => {
    return (
      <div className="flex flex-col">
        {packages.map((item) => (
          <div className="pb-8 last:pb-0" key={item.key}>
            <EquipmentCollapseContent title={item.key} items={item.items} />
          </div>
        ))}
      </div>
    );
  };

  const renderEquipmentPackages = useCallback(
    (packages: EquipmentItem[]) => {
      const title = i18n.formatMessage({
        id: 'pdp.equipmentDetails.packages',
      });

      if (isExpPDPCarInfo) {
        return (
          <>
            <p className="body-14-semibold mb-4 mt-8">{title}</p>
            {buildEquipmentPackagesContainer(packages)}
          </>
        );
      }

      return (
        <Collapse
          onValueChange={(expanded?: string[]) =>
            trackEquipmentCollapseClicked(title, Boolean(expanded?.length))
          }
          title={<Title title={title} />}
        >
          {buildEquipmentPackagesContainer(packages)}
        </Collapse>
      );
    },
    [i18n, trackEquipmentCollapseClicked]
  );

  const hasAnyPackages = Object.keys(packageItems).length > 0;

  return (
    <>
      {equipmentItems.map(renderItem)}
      {hasAnyPackages && renderEquipmentPackages(packageItems)}
    </>
  );
};

export default React.memo(EquipmentDetails);
