import { CarInformationDrawer } from '@finn/auto-ui/modules/products/details/components/CarInformationDrawer/CarInformationDrawer';
import { SpacingWrapper } from '@finn/platform-modules';
import { ModalContainer, ModalKey } from '@finn/ua-modals';
import { GenericVehicleDetails, Review } from '@finn/ua-vehicle';
import { cn, isMobileApp } from '@finn/ui-utils';
import React, { ReactNode } from 'react';

import KeyData from '~/components/KeyData/KeyData';
import ProductDetails from '~/components/ProductDetails';
import { AddToCartProvider } from '~/components/ProductDetails/AddToCartProvider';
import { ErrorPageData, PDPData, PDPModules } from '~/types/cosmicModules';

import { DownloadButtons } from '../Equipment/Equipment';
import PageBreadcrumbs from '../PageBreadcrumbs';
import { useIsPDPCarInfoExp } from '../ProductDetails/utils/useIsPDPCarInfoExp';
import { ComparisonTable } from './ComparisonTable';
import { FrequentlyAskedQuestions } from './FrequentlyAskedQuestions';
import { MetaContent } from './MetaContent';
import { ProductSlider } from './ProductSlider';
import { TrustPilot } from './TrustPilot';

type Props = {
  vehicle: GenericVehicleDetails;
  reviews: Review[];
  pdpModules: PDPModules;
  pageData: PDPData | ErrorPageData;
  renderMetaContent?: () => ReactNode;
};

const VehicleData: React.FC = () => {
  return (
    <div className="container">
      <KeyData />
    </div>
  );
};

export const ProductDetailsContainer: React.FC<Props> = ({
  vehicle,
  reviews,
  pdpModules,
  pageData,
  renderMetaContent,
}) => {
  const isApp = isMobileApp();

  const isExpPDPCarInfo = useIsPDPCarInfoExp();

  const productPageData = pageData as PDPData;
  const productDetailsData =
    productPageData?.metadata?.content?.product_details ?? {};

  return (
    <div
      className={cn('md:mt-6', isApp && 'mt-6')}
      data-appid="product-details"
    >
      <MetaContent renderMetaContent={renderMetaContent} />
      <div className="container">
        <PageBreadcrumbs />
      </div>
      <AddToCartProvider vehicle={vehicle}>
        <ProductDetails pageData={productPageData} hideBreadCrumbs={isApp} />
        <ProductSlider pdpModules={pdpModules} />
        {!isExpPDPCarInfo && (
          <>
            <VehicleData />
            <div className="container">
              <div className="mt-4">
                <DownloadButtons productDetailsData={productDetailsData} />
              </div>
            </div>
          </>
        )}
        <ComparisonTable pdpModules={pdpModules} />
        <TrustPilot pdpModules={pdpModules} reviews={reviews} />
        <FrequentlyAskedQuestions pdpModules={pdpModules} />
        <SpacingWrapper />
        {isExpPDPCarInfo && (
          <ModalContainer
            modalKey={ModalKey.PDP_CAR_INFO}
            ModalComponent={CarInformationDrawer}
            productDetailsData={productDetailsData}
          />
        )}
      </AddToCartProvider>
    </div>
  );
};
