import { Button } from '@finn/design-system/atoms/button';
import { Features, useIsABVariant } from '@finn/ua-featureflags';
import { cn, useFormattedPrice } from '@finn/ui-utils';
import get from 'lodash/get';
import React, { useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';

import PDPTracking from '~/components/ProductDetails/utils/PDPTracking';
import { useTrackingData } from '~/components/ProductDetails/utils/useTrackingData';
import VehicleContext, { getEquipmentLine } from '~/contexts/Vehicle';
import { ProductDetailsData } from '~/types/cosmicModules';
import { parseToHtml } from '~/utils/html';

import { useIsPDPCarInfoExp } from '../ProductDetails/utils/useIsPDPCarInfoExp';

type EquipmentInfoProps = {
  label: string;
  value: string;
};

const EquipmentInfo: React.FunctionComponent<EquipmentInfoProps> = ({
  label,
  value,
}) => {
  const isExpPDPCarInfo = useIsABVariant(Features.ExpPDPCarInfo);

  return (
    <h6
      className={
        isExpPDPCarInfo ? 'body-14-semibold mb-2' : 'global-t6-semibold mt-3'
      }
    >{`${label}: ${value}`}</h6>
  );
};

export const DownloadButtons: React.FunctionComponent<{
  productDetailsData: any;
  modalView?: boolean;
}> = ({ productDetailsData, modalView = false }) => {
  const i18n = useIntl();
  const trackingData = useTrackingData();
  const { vehicle } = useContext(VehicleContext);

  const trackDownload = useCallback(() => {
    PDPTracking.pdfConfigClicked(trackingData);
  }, [trackingData]);

  const configFileURL = get(vehicle, 'config.link');

  const downloadDetailsLabel = i18n.formatMessage({
    id: 'pdp.equipment.downloadDetails',
  });

  const configWarrantyURL = get(vehicle, 'config_warranty.url');

  // TODO: I did not find the corresponding value in the PDP object; Should we remove this?
  const warrantyInformationLabel = get(
    productDetailsData,
    'equipment_section.warranty_information',
    ''
  );

  return (
    <div className="flex flex-wrap">
      {configFileURL && (
        <div className={!modalView && 'mr-6 mt-4'}>
          <Button
            target="_blank"
            onMouseDown={trackDownload}
            className="px-6 py-4"
            href={configFileURL}
            variant="outline"
            size="lg"
          >
            {downloadDetailsLabel}
          </Button>
        </div>
      )}
      {configWarrantyURL && (
        <div className={!modalView && 'mr-6 mt-4'}>
          <Button
            target="_blank"
            href={configWarrantyURL}
            variant="outline"
            size="md"
          >
            {warrantyInformationLabel}
          </Button>
        </div>
      )}
    </div>
  );
};

interface IProps {
  productDetailsData: ProductDetailsData;
  modalView?: boolean;
  showTitle?: boolean;
}
const Equipment: React.FunctionComponent<IProps> = ({
  productDetailsData,
  modalView,
  showTitle = true,
}) => {
  const drawerView = useIsPDPCarInfoExp();

  const { vehicle } = useContext(VehicleContext);
  const i18n = useIntl();

  const equipmentLine = getEquipmentLine(vehicle);
  const description = get(vehicle, 'description', '');

  const title = i18n.formatMessage({
    id: 'pdp.equipment.configuration',
  });

  const equipmentLineLabel = i18n.formatMessage({
    id: 'pdp.equipment.equipmentLine',
  });

  const equipmentMSRPLabel = i18n.formatMessage({
    id: 'pdp.equipment.manufacturerPrice',
  });

  const msrp = get(vehicle, 'price.msrp', 0);

  const shouldDisplayMSRP = Boolean(equipmentMSRPLabel && msrp);
  const msrpFormatted = useFormattedPrice(msrp);

  return (
    <div className={cn({ 'mt-8 pb-0': drawerView }, 'pb-8 pr-20')}>
      {showTitle && (
        <div className="mb-6">
          <h2
            className={
              modalView
                ? 'global-t5-semibold'
                : drawerView
                  ? 'body-16-semibold mb-8'
                  : 'mobile-t3-semibold md:web-t3-semibold'
            }
          >
            {title}
          </h2>
        </div>
      )}
      <div className="mb-1">
        {shouldDisplayMSRP && (
          <EquipmentInfo label={equipmentMSRPLabel} value={msrpFormatted} />
        )}
        {equipmentLine && (
          <EquipmentInfo label={equipmentLineLabel} value={equipmentLine} />
        )}
      </div>
      {description && (
        <p className="body-16-light mt-6 pb-4">{parseToHtml(description)}</p>
      )}
      <DownloadButtons
        modalView={modalView}
        productDetailsData={productDetailsData}
      />
    </div>
  );
};

export default Equipment;
