import { Button } from '@finn/design-system/atoms/button';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalTitle,
  ModalTrigger,
} from '@finn/design-system/atoms/modal';
import { DeliveryFilled } from '@finn/design-system/icons/delivery-filled';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { getEarliestDelivery, VehicleContext } from '@finn/ua-vehicle';
import Link from 'next/link';
import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';

export const AvailabilityBox = () => {
  const intl = useIntl();
  const { vehicle } = useContext(VehicleContext);

  const deliveryText = useMemo(() => {
    const date = getEarliestDelivery({ vehicle, intl });

    return intl.formatMessage({ id: 'pdp.deliverableFrom' }, { date });
  }, [intl, vehicle]);

  const trackModalOpen = (opened: boolean) => {
    if (!opened) return;

    interactionTrackingEvent(TrackingEventName.MODAL_OPENED, {
      name: 'Delivery Details',
    });
  };

  return (
    <Modal onOpenChange={trackModalOpen}>
      <div className="bg-snow flex items-center space-x-4 px-4 py-6">
        <DeliveryFilled />
        <div className="flex-col space-y-3">
          <div className="body-14-light">{deliveryText}</div>
          <ModalTrigger asChild>
            <Button variant="action" size="md">
              {intl.formatMessage({ id: 'pdp.availabilityInfo' })}
            </Button>
          </ModalTrigger>
        </div>
      </div>
      <ModalContent>
        <ModalHeader>
          <ModalTitle>
            {intl.formatMessage({ id: 'pdp.availabilityInfoModal.title' })}
          </ModalTitle>
        </ModalHeader>
        <p className="body-16-light">
          {intl.formatMessage({
            id: 'pdp.availabilityInfoModal.description',
          })}
        </p>
        <p className="body-16-light">
          <span className="body-16-semibold inline-block">
            {intl.formatMessage({
              id: 'pdp.availabilityInfoModal.dayConfidence.title',
            })}
          </span>
          {` ${intl.formatMessage({
            id: 'pdp.availabilityInfoModal.dayConfidence.description',
          })}`}
        </p>
        <p className="body-16-light">
          <span className="body-16-semibold inline-block">
            {intl.formatMessage({
              id: 'pdp.availabilityInfoModal.monthConfidence.title',
            })}
          </span>
          {` ${intl.formatMessage({
            id: 'pdp.availabilityInfoModal.monthConfidence.description',
          })}`}
        </p>
        <p className="body-16-light">
          <span className="body-16-semibold flex">
            {intl.formatMessage({
              id: 'pdp.availabilityInfoModal.swap.title',
            })}
          </span>
          {intl.formatMessage({
            id: 'pdp.availabilityInfoModal.swap.description',
          })}
        </p>
        <p className="body-16-light">
          <span className="body-16-semibold flex">
            {intl.formatMessage({
              id: 'pdp.availabilityInfoModal.questions.title',
            })}
          </span>
          {intl.formatMessage(
            { id: 'pdp.availabilityInfoModal.questions.description' },
            {
              support: (chunks) => (
                <Link href="/contact">
                  <a target="_blank" className="underline">
                    {chunks}
                  </a>
                </Link>
              ),
            }
          )}
        </p>
      </ModalContent>
    </Modal>
  );
};
