import {
  ContractDuration,
  DistanceOptions,
} from '@finn/auto-ui/components/ConfigurePage';
import { useConfigureStore } from '@finn/auto-ui/components/ConfigurePage/ConfigurePageStore';
import { ContinueButton } from '@finn/auto-ui/components/ConfigurePage/ContinueButton';
import { DownPayment } from '@finn/auto-ui/components/ConfigurePage/DownPayment/DownPayment';
import { Login } from '@finn/auto-ui/modules/login/Login';
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
} from '@finn/design-system/atoms/drawer';
import { useMediaQuery } from '@finn/design-system/helpers/media';
import { DEFAULT_DOWN_PAYMENT } from '@finn/ua-constants';
import { Features, useIsABVariant } from '@finn/ua-featureflags';
import {
  interactionTrackingEvent,
  TrackingEventName,
  useTrackingStore,
} from '@finn/ua-tracking';
import { VehicleContext } from '@finn/ua-vehicle';
import {
  calculateTotalPrice,
  getForBusinessCookie,
  triggerEcommerceOrderingTrackingEvent,
  usePreDiscountRawValue,
} from '@finn/ui-modules';
import { getFormattedPrice, isMobileApp, useSession } from '@finn/ui-utils';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';

import { usePartnerDiscount } from '~/hooks/usePartnerDiscount';

import { useMonthlySubscriptionCost } from '../PriceBreakdown/hooks/useMonthlySubscriptionCost';
import { ConfigureVehicleHeader } from './ConfigureVehicleHeader';
import { DownpaymentOptionsModal } from './DownpaymentModal';
import { PriceDisplay } from './PriceDisplay';

enum PCPStep {
  ConfigureStep = 1,
  LoginStep = 2,
}

type ConfigureVehicleModalProps = {
  open: boolean;
  onClose: () => void;
  loadingEdit?: boolean;
  handleEditConfig?: () => void;
};

export const ConfigureVehicleModal: React.FC<ConfigureVehicleModalProps> = ({
  open,
  onClose,
  handleEditConfig,
  loadingEdit,
}) => {
  const intl = useIntl();
  const partnerDiscount = usePartnerDiscount();
  const track = useTrackingStore((state) => state.track);

  useEffect(() => {
    if (partnerDiscount) {
      track(TrackingEventName.PCP_PARTNER_DISCOUNT_SHOWED, {
        additionalProps: { partnerDiscount },
      });
    }
  }, [partnerDiscount, track]);

  const { vehicle } = useContext(VehicleContext);

  const {
    term,
    onContinue,
    downPayment: isDownPayment,
    isLoading: isLoadingFromStore,
    kilometerPackage,
    monthlyPrice,
  } = useConfigureStore();

  const monthlyPriceWithoutDownpayment = calculateTotalPrice({
    vehicle,
    kilometerPackage,
    term,
    isDownPayment: false,
  });

  const [isFakeDoorOpen, setIsFakeDoorOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(PCPStep.ConfigureStep);

  const isCheckoutEditModal = Boolean(handleEditConfig);

  const [session] = useSession();
  const isExpPCPLogin = useIsABVariant(Features.ExpPCPLogin2) && !isMobileApp();
  const isPrevStateAvailable =
    isExpPCPLogin && currentStep === PCPStep.LoginStep;

  const isForBusiness = getForBusinessCookie();

  const { total, monthlyCost } = useMonthlySubscriptionCost(vehicle);
  let totalWithDiscount = total;
  let preDiscountPrice = usePreDiscountRawValue(
    vehicle,
    monthlyCost,
    term,
    isForBusiness,
    isDownPayment
  );

  if (partnerDiscount > 0) {
    if (!preDiscountPrice) {
      preDiscountPrice = total;
    }
    totalWithDiscount = total - total * partnerDiscount;
  }

  const originalPriceFormatted = getFormattedPrice(
    intl,
    preDiscountPrice || 0,
    0
  );
  const finalPriceFormatted = getFormattedPrice(intl, totalWithDiscount, 0);

  const subtitle = intl.formatMessage({
    id: `pdp.price.${isForBusiness ? 'b2b' : 'b2c'}_vat`,
  });

  const isShortPreDiscountPrice = preDiscountPrice?.toString().length === 4;

  const handleModalClose = useCallback(() => {
    interactionTrackingEvent(TrackingEventName.MODAL_CLOSED, {
      name: 'configure',
    });
    onClose();
  }, [onClose]);

  const handleContinue = useCallback(() => {
    if (handleEditConfig) {
      handleEditConfig();

      return;
    }
    if (isExpPCPLogin && currentStep === PCPStep.ConfigureStep && !session) {
      setCurrentStep(PCPStep.LoginStep);
      triggerEcommerceOrderingTrackingEvent({
        eventName: TrackingEventName.PRODUCT_ADDED,
        kmPackage: kilometerPackage,
        monthlyPrice,
        monthlyPriceWithoutDownpayment,
        downPaymentAmount: isDownPayment ? DEFAULT_DOWN_PAYMENT : undefined,
        term,
        vehicle,
      });
    } else {
      onContinue();
    }
  }, [handleEditConfig, isExpPCPLogin, onContinue]);

  useEffect(() => {
    if (session && currentStep === PCPStep.LoginStep) {
      onContinue();
    }
  }, [session]);

  const isMDScreen = useMediaQuery('md');

  const buildConfigureSection = useMemo(() => {
    return (
      <>
        <ConfigureVehicleHeader vehicle={vehicle} />
        {!isCheckoutEditModal && <DownPayment />}
        <ContractDuration />
        <DistanceOptions />
      </>
    );
  }, [vehicle, isCheckoutEditModal]);

  const buildFooterSection = useMemo(() => {
    return (
      <DrawerFooter>
        <PriceDisplay
          subtitle={subtitle}
          finalPrice={finalPriceFormatted}
          originalPrice={originalPriceFormatted}
          isShortPrice={isShortPreDiscountPrice}
          hasOriginalPrice={Boolean(preDiscountPrice)}
        />

        <div className="w-1/2">
          <ContinueButton
            isInPDP
            testId="submit-config"
            onClick={handleContinue}
            isLoading={isLoadingFromStore || loadingEdit}
          />
        </div>

        <DownpaymentOptionsModal
          isOpen={isFakeDoorOpen}
          onClose={() => setIsFakeDoorOpen(false)}
        />
      </DrawerFooter>
    );
  }, [
    subtitle,
    finalPriceFormatted,
    originalPriceFormatted,
    isShortPreDiscountPrice,
    preDiscountPrice,
    isLoadingFromStore,
    loadingEdit,
    isFakeDoorOpen,
  ]);

  return (
    <Drawer
      open={open}
      onClose={handleModalClose}
      handleOnly
      direction={isMDScreen ? 'bottom' : 'right'}
    >
      <DrawerContent
        title={intl.formatMessage({ id: 'pdp.configure.generalTitle' })}
        onBack={
          isPrevStateAvailable
            ? () => setCurrentStep(PCPStep.ConfigureStep)
            : undefined
        }
        className="z-[1302]"
      >
        {isExpPCPLogin ? (
          <>
            {currentStep === PCPStep.ConfigureStep && buildConfigureSection}
            {currentStep === PCPStep.LoginStep && (
              <Login isLoadingRestore={!!session} />
            )}
          </>
        ) : (
          buildConfigureSection
        )}

        {(!isExpPCPLogin ||
          (isExpPCPLogin && currentStep === PCPStep.ConfigureStep)) &&
          buildFooterSection}
      </DrawerContent>
    </Drawer>
  );
};
