import { Check } from '@finn/design-system/icons/check';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import React, { useState } from 'react';

import { useFilteredFearAwayItems } from './useFilteredFearAwayItems';
import VehiclesBenefitsModal from './VehiclesBenefitsModal';

const ClickableVehiclesBenefits: React.FunctionComponent = () => {
  const [modalData, setModalData] = useState({ isShown: false, index: null });
  const { filteredFearAwayItems } = useFilteredFearAwayItems();

  const handleBenefitClick = (index: number) => () => {
    setModalData({ isShown: true, index });
    interactionTrackingEvent(TrackingEventName.MODAL_OPENED, {
      name: filteredFearAwayItems[index].title,
    });
  };

  const handleModalClose = () => {
    setModalData((prevModalData) => ({ ...prevModalData, isShown: false }));
    interactionTrackingEvent(TrackingEventName.MODAL_CLOSED, {
      name: filteredFearAwayItems[modalData.index]?.title,
    });
  };

  return (
    <>
      {filteredFearAwayItems.map(({ title }, index) => (
        <div key={title} className="flex items-center pb-4">
          <Check className="mr-2 h-4 w-4 flex-shrink-0" />
          <div onClick={handleBenefitClick(index)}>
            <p className="body-16-semibold cursor-pointer underline">{title}</p>
          </div>
        </div>
      ))}
      <VehiclesBenefitsModal
        isOpen={modalData.isShown}
        index={modalData.index}
        onClose={handleModalClose}
      />
    </>
  );
};

export default ClickableVehiclesBenefits;
