import { useMediaQuery } from '@finn/design-system/helpers/media';
import { VehicleContext } from '@finn/ua-vehicle';
import { cn, getCloudinaryImgUrl, getCloudinarySrcSet } from '@finn/ui-utils';
import React, { useContext, useState } from 'react';

import useIsomorphicLayoutEffect from '~/hooks/useLayoutEffectSSR';
import useScrollBlock from '~/hooks/useScrollBlock';

import ImageGalleryDesktop from './ImageGalleryDesktop';
import ImageGalleryMobile from './ImageGalleryMobile';

type Props = {
  imageUrls: string[];
  labelsBar: React.ReactNode;
  showDisclaimer?: boolean;
  disclaimerText: string;
  fullScreen: boolean;
  setFullScreen: Function;
};

const ImageGallery: React.FC<Props> = (props) => {
  const isMobile = useMediaQuery('sm');

  const { vehicle } = useContext(VehicleContext);
  const [initialSlide, setInitialSlide] = useState(0);
  const [blockScroll, allowScroll] = useScrollBlock();

  useIsomorphicLayoutEffect(() => {
    if (props.fullScreen) {
      blockScroll();
    } else {
      allowScroll();
    }

    return allowScroll;
  }, [props.fullScreen]);

  const imageUrls = props.imageUrls.map((imageUrl) =>
    getCloudinaryImgUrl(imageUrl, {
      w: 360,
      dpr: 2,
      ar: '8:5',
      c: 'lpad',
      b: 'white',
    })
  );

  const imageThumbUrls = props.imageUrls.map((imageUrl) =>
    getCloudinaryImgUrl(imageUrl, {
      w: 72,
      dpr: 2,
      ar: '8:5',
      c: 'lpad',
      b: 'white',
    })
  );

  const imageSrcSets = props.imageUrls.map((imageUrl) =>
    getCloudinarySrcSet(imageUrl, [400, 800])
  );

  const imageSrcSetsZoom = props.imageUrls.map((imageUrl) =>
    getCloudinarySrcSet(imageUrl, [1200, 1600])
  );

  const vehicleName = `${vehicle.brand?.id} ${vehicle.model}`;

  return (
    <>
      <div className={cn('block sm:hidden', { '!block': props.fullScreen })}>
        <ImageGalleryMobile
          {...props}
          labelsBar={props.labelsBar}
          imageUrls={imageUrls}
          imageThumbUrls={imageThumbUrls}
          imageSrcSets={props.fullScreen ? imageSrcSetsZoom : imageSrcSets}
          initialSlide={initialSlide}
          isMobile={isMobile}
          altText={vehicleName}
        />
      </div>
      <div
        className={cn('hidden sm:block', {
          '!hidden': props.fullScreen,
        })}
      >
        <ImageGalleryDesktop
          {...props}
          labelsBar={props.labelsBar}
          imageUrls={imageUrls}
          imageSrcSets={imageSrcSets}
          setInitialSlide={setInitialSlide}
          altText={vehicleName}
        />
      </div>
    </>
  );
};
export default ImageGallery;
