import { useMediaQuery } from '@finn/design-system/helpers/media';
import { VehicleContext } from '@finn/ua-vehicle';
import { cn, useFormattedPrice } from '@finn/ui-utils';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import { useIsPDPCarInfoExp } from '../ProductDetails/utils/useIsPDPCarInfoExp';
import { EmissionDetails } from './EmissionDetails';
import KeyDataListItem, { IListItem } from './KeyDataListItem';

interface IProps {
  items: IListItem[];
  modalView?: boolean;
  configureView?: boolean;
}

const KeyDataList: React.FunctionComponent<IProps> = ({
  items,
  modalView,
  configureView,
}) => {
  const i18n = useIntl();
  const { vehicle } = useContext(VehicleContext);
  const msrp = vehicle?.price?.msrp ?? 0;
  const msrpFormatted = useFormattedPrice(msrp);

  const drawerView = useIsPDPCarInfoExp();

  const isLarge = !useMediaQuery('sm');
  const equipmentMSRPLabel = i18n.formatMessage({
    id: 'pdp.equipment.manufacturerPrice',
  });

  const shouldDisplayMSRP = Boolean(equipmentMSRPLabel && msrp);

  if (shouldDisplayMSRP) {
    items.push({
      id: 0,
      label: msrpFormatted,
      title: equipmentMSRPLabel,
    });
  }

  return (
    <div
      className={cn('mt-4 flex w-full flex-wrap', {
        'mt-0': configureView,
      })}
    >
      {items
        .filter((item) => !!item.label)
        .map((item) => (
          <div
            key={item.id}
            className={cn(
              'w-full max-w-full sm:max-w-[50%] md:max-w-[calc(100%/3)] lg:max-w-[25%]',
              {
                '!max-w-full': configureView || drawerView,
                'md:max-w-[50%] lg:max-w-[calc(100%/3)]': modalView,
                'p-1': configureView,
                'p-5': isLarge && !configureView,
                'p-3': !configureView && !isLarge,
                '!pb-6 !pl-0 !pt-0': drawerView,
              }
            )}
          >
            <KeyDataListItem {...item} configureView={configureView} />
          </div>
        ))}

      <div
        className={cn(
          'w-full max-w-full sm:max-w-[50%] md:max-w-[calc(100%/3)] lg:max-w-[25%]',
          {
            '!max-w-full': configureView || drawerView,
            'md:max-w-[50%] lg:max-w-[calc(100%/3)]': modalView,
            'p-1': configureView,
            'p-5': isLarge && !configureView,
            'p-3': !configureView && !isLarge,
            '!pb-6 !pl-0 !pt-0': drawerView,
          }
        )}
      >
        <EmissionDetails configureView={configureView} />
      </div>
    </div>
  );
};

export default KeyDataList;
