import KeyData from '@finn/auto-ui/components/KeyData';
import { EquipmentDetailsComponent } from '@finn/auto-ui/components/PDP/EquipmentDetailsComponent';
import VehiclesModel from '@finn/auto-ui/components/ProductDetails/VehiclesModel';
import { Drawer, DrawerContent } from '@finn/design-system/atoms/drawer';
import { useMediaQuery } from '@finn/design-system/helpers/media';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import React, { useCallback } from 'react';

interface IProps {
  open: boolean;
  onClose: () => void;
  productDetailsData: any;
}

export const CarInformationDrawer: React.FunctionComponent<IProps> = ({
  open,
  onClose,
  productDetailsData,
}) => {
  const isMDScreen = useMediaQuery('md');

  const handleModalClose = useCallback(() => {
    interactionTrackingEvent(TrackingEventName.MODAL_CLOSED, {
      name: 'carinfo',
    });
    onClose();
  }, [onClose]);

  return (
    <Drawer
      open={open}
      onClose={handleModalClose}
      direction={isMDScreen ? 'bottom' : 'right'}
    >
      <DrawerContent className="overflow-visible ">
        <VehiclesModel drawerView={true} />
        <KeyData />
        <hr className="border-pearl w-full border-0 border-b border-solid" />
        <EquipmentDetailsComponent productDetailsData={productDetailsData} />
        <div className="pb-16" />
      </DrawerContent>
    </Drawer>
  );
};
